<template>
  <div class="px-7 py-3">
    <div><h3>Trash</h3></div>
    <div v-if="trash && !loading">
      <div class="d-flex" v-if="trash.data.length">
        <section style="width: 20%" class="px-3 mt-3">
          <v-checkbox
            dense
            hide-details
            @click="selectAll"
            v-model="isSelectAll"
            :value="true"
            label="Select All"
            class="ma-0"
          ></v-checkbox>
        </section>
        <section style="width: 80%" class="d-flex justify-end">
          <v-btn
            v-if="!deleting"
            dark
            color="red"
            class="text-capitalize mr-2"
            depressed
            :disabled="!selectedItem.length"
            @click="dialogPermanent = true"
          >
            <v-icon class="mr-1">mdi-delete</v-icon>
            delete
          </v-btn>
          <v-btn
            v-if="restoring"
            dark
            color="blue"
            class="text-capitalize"
            depressed
            ><v-progress-circular
              :size="25"
              class="mr-2"
              indeterminate
            ></v-progress-circular>
            Loading</v-btn
          >
          <v-btn
            v-if="!restoring"
            dark
            color="blue"
            class="text-capitalize"
            depressed
            :disabled="!selectedItem.length"
            @click="restore"
          >
            <v-icon class="mr-1">mdi-restore</v-icon>
            restore
          </v-btn>
          <v-btn
            v-if="deleting"
            dark
            color="red"
            class="text-capitalize"
            depressed
            ><v-progress-circular
              :size="25"
              class="mr-2"
              indeterminate
            ></v-progress-circular>
            Loading</v-btn
          >
        </section>
      </div>
      <div>
        <div class="d-flex pa-3">
          <div style="width: 5% ">
          </div>
          <div style="width: 90%">
            <v-row>
              <v-col cols="1">ID</v-col>
              <v-col cols="4">FULL NAME</v-col>
              <v-col cols="3">EMAIL</v-col>
              <v-col cols="2">ROLE</v-col>
              <v-col cols="2"> DELETED AT </v-col>
            </v-row>
          </div>
        </div>
        <v-card
          class="pa-3 d-flex mb-2"
          flat
          v-for="x in trash.data"
          :key="x.id"
        >
          <div style="width: 5%">
            <v-checkbox
              dense
              hide-details
              v-model="selectedItem"
              :value="x.id"
              class="ma-0"
            ></v-checkbox>
          </div>
          <div style="width: 90%">
            <v-row>
              <v-col cols="1">{{ x.id }}</v-col>
              <v-col cols="4">{{ x.nama_lengkap }}</v-col>
              <v-col cols="3">{{ x.email }}</v-col>
              <v-col cols="2">{{ x.role }}</v-col>
              <v-col cols="2">
                {{ $date(x.deleted_at).format("DD MMMM YYYY") }}
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <deleteUserPermanent
        v-bind:dialogPermanent="dialogPermanent"
        v-bind:dataItem="selectedItem"
        @del="permanentDelete"
        @close="closeDialog(1)"
      />
      <div class="d-flex justify-center align-center" style="height:80vh" v-if="!trash.data.length">
        <div>
          <img
            src="../../../assets/img/empty-trash.svg"
            height="250px"
            alt=""
          />
          <h2 class="text-center">Trash Empty</h2>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <loadingLG />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import loadingLG from "../../../components/etc/loading-component/loadingLG.vue";
import DeleteUserPermanent from "./deleteUserPermanent.vue";

export default {
  components: { loadingLG, DeleteUserPermanent },
  name: "TRASH",
  computed: {
    ...mapState({
      trash: (state) => state.trash.trash,
    }),
  },
  data() {
    return {
      selectedItem: [],
      isSelectAll: null,
      deleting: false,
      loading: false,
      restoring: false,
      dialogPermanent: false,
    };
  },
   watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.getItem();
    },
  },
  mounted() {
    this.getItem();
  },
  methods: {
    selectAll() {
      if (this.isSelectAll) {
        let data = this.trash.data.map((x) => {
          return x.id;
        });
        this.selectedItem = data;
      } else {
        this.selectedItem = [];
      }
    },
    getItem() {
      let data = {
        page: 1,
        limit: 100,
        role: this.$route.params.role.toUpperCase()
      };
      this.loading = true;
      this.$store.dispatch("trash/listTrash", data).then((data) => {
        if (data) {
          this.loading = false;
        }
      });
    },
    permanentDelete() {
      let data = {
        id: this.selectedItem,
      };
      this.deleting = true;
      this.$store
        .dispatch("user/permanentDeleteBulk", data)
        .then((data) => {
          this.deleting = false;
          this.dialogPermanent = false;
          this.getItem();
          this.selectedItem = [];
          if (data.status == "success") {
            this.Swal("success", data.message);
          } else {
            this.Swal("error", data.message);
          }
        })
        .catch((err) => {
          this.deleting = false;
          this.Swal("error", err.message);
        });
    },
    restore() {
      let body = {
        id: this.selectedItem,
      };
      let data = {
        body: body,
        type: "user",
      };
      this.restoring = true;
      this.$store
        .dispatch("trash/restoreItem", data)
        .then((data) => {
          this.restoring = false;
          this.getItem();
          this.selectedItem = [];
          if (data.status == "success") {
            this.Swal("success", data.message);
          } else {
            this.Swal("error", data.message);
          }
        })
        .catch((err) => {
          this.Swal("error", err.message);
        });
    },
    closeDialog(q) {
      switch (q) {
        case 1:
          this.dialogPermanent = false;
          break;
      }
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top",
        toast: true,
        timer: 3000,
      });
    },
  },
};
</script>

<style>
</style>