<template>
  <v-row justify="start">
    <v-dialog v-model="dialogPermanent" persistent max-width="400px">
      <v-card class="rounded-max" v-if="dataItem">
        <v-toolbar dense flat>
          <!-- <h2>Move Report To trash</h2> -->
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-container>
          <div class="d-flex flex-column align-center" v-if="dataItem.length">
            <img
              src="../../../assets/img/delete-icon.png"
              height="150px"
              alt=""
            />
            <div>
              <p class="ma-0 text-center">
                Are you <b>ABSOLUTELY SURE</b> you wish to delete {{ dataItem.length }} items?
                This process cannot be undone.
              </p>
            </div>
          </div>
          <div v-if="!dataItem.length" class="mb-3">
            <h3 class="text-center">Please select the item to be deleted</h3>
          </div>
        </v-container>
        <v-card-actions v-if="dataItem.length">
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            class="text-capitalize"
            text
            @click="$emit('close')"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!loading"
            color="red"
            class="text-capitalize"
            depressed
            dark
            @click="deleteItem"
            ><v-icon class="mr-2">mdi-delete</v-icon>
            <p class="ma-0">delete</p></v-btn
          >
          <v-btn
            v-if="loading"
            color="red"
            class="text-capitalize"
            dark
            depressed
            ><v-progress-circular
              :size="25"
              class="mr-2"
              indeterminate
            ></v-progress-circular>
            Loading</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "dialogPermanent",
  data() {
    return {
      loading: false,
    };
  },
  props: ["dialogPermanent", "dataItem"],

  methods: {
    deleteItem() {
      this.loading = true;
      this.$emit("del");
    },
  },
};
</script>

<style></style>
